<template>
  <div class="cleanContainer">
    <div class="box">
      <div class="title">
        <div class="logo"></div>
        <span class="text">安心清理专家</span>
      </div>
      <div class="content">
        <div class="right">
          <div class="header">
            <h4 class="title">安心清理专家</h4>
            <p class="desc">快速找到垃圾文件，释放更多可用空间！</p>
          </div>
          <div class="bottom">
            <h4 class="title">功能介绍</h4>
            <p class="desc">清理释放无用资源，提升手机速度，改善手机卡顿和闪退问题。优化游戏体验。关闭后台自启应用，达到 CPU快速降温效果，恢复手机的良好性能。</p>
          </div>
          <a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.anxin.clean.expert.speed">
            <div class="download">
              <i class="icon-android"></i>
              <span class="text">Android下载</span>
            </div>
          </a>
        </div>
        <div class="left"></div>
      </div>
    </div>
    <div class="footer">
      <div class="title">
        <div class="bg">ABOUT US</div>
        <div class="text">公司介绍</div>
      </div>
      <div class="content">广州极速飞驰网络有限公司是国内专注于个人用户移动应用技术领域的研究开发的高新科技公司，通过APP自主研发、场景化精准流量分发、精细化用户运营体系等核心技术为用户提供一系列应用服务。公司始终秉承重服务守信用的经营理念，在满足用户个性化需求的前提下，关注产品质量和体验感。公司核心团队拥有多年的产品研发经验，具有极高的产品设计和研发水平以及高效的研发效率，以极短的时间创建了用户规模过百万的产品矩阵，并保持着持续高速的发展。</div>

      <div class="bottom">
        <h4 class="domainInfo"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备20010837号</a>|广州极速飞驰网络有限公司</h4>
        <p class="desc">地址：广州市天河区庆亿街1号802-806房及9-19层（部位1102B）</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped lang="scss">
  .cleanContainer {
    .box {
      padding: 70px 180px 110px 320px;
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      background: url('../assets/clean_bbg.png') no-repeat center center;
      background-size: cover;
      .title {
        margin-bottom: 48px;
        display: flex;
        align-items: center;
        .logo {
          margin-right: 16px;
          display: inline-block;
          height: 77px;
          width: 77px;
          background: url('../assets/clean_logo.png') no-repeat center center;
          background-size: cover;
        }
        .text {
          font-size: 36px;
          font-weight: 500;
          color: #000000;
          line-height: 50px;
        }
      }
      .content {
        display: flex;
        .left {
          margin-left: 65px;
          margin-right: 166px;
          display: block;
          width: 648px;
          height: 673px;
          background: url('../assets/clean_bg.png') no-repeat center center;
          background-size: cover;
        }
        .right {
          flex: 1;
          .header {
            margin-bottom: 54px;
            .title {
              margin-bottom: 15px;
              font-size: 48px;
              font-weight: 500;
              color: #000000;
              line-height: 67px;
              letter-spacing: 1px;
            }
            .desc {
              font-size: 28px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.89);
              line-height: 40px;
            }
          }
          .bottom {
            margin-bottom: 47px;
            .title {
              margin-bottom: 12px;
              font-size: 28px;
              font-weight: 500;
              color: #000000;
              line-height: 40px;
            }
            .desc {
              font-size: 16px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.89);
              line-height: 26px;
            }
          }
          .download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 226px;
            height: 60px;
            background: url('../assets/clean_btn.png') no-repeat center center;
            background-size: cover;
            cursor: pointer;
            .icon-android {
              margin-right: 15px;
              display: inline-block;
              width: 32px;
              height: 38px;
              background: url('../assets/android.png') no-repeat center center;
              background-size: cover;
            }
            .text {
              font-size: 22px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 30px;
            }
          }
        }
      }
    }
    .footer {
      padding: 70px 412px 48px 420px;
      box-sizing: border-box;
      margin-left: -4px;
      background: #FBFBFB;
      .title {
      position: relative;
       display: flex;
       justify-content: center;
       align-items: center;
       .bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          color: #EEEEEE;
          font-size: 49px;
          font-weight: bold;
          z-index: 0;
        }
         .text {
          position: relative;
          top: 10px;
          font-size: 28px;
          font-weight: 500;
          color: #000000;
          line-height: 40px;
        }
      }
      .content {
        margin-top: 24px;
        margin-bottom: 58px;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.89);
        line-height: 28px;
      }
      .bottom {
        .domainInfo {
          text-align: center;
          margin: 0;
          margin-bottom: 6px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.89);
          line-height: 22px;
          a {
            color: rgba(0, 0, 0, 0.89);
          }
        }
        .desc {
          text-align: center;
          margin-bottom: 48px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.6);
          line-height: 22px;
        }
      }
    }
    a {
      display: inline-block;
      text-decoration: none;
    }
  }

</style>
